import React, { FunctionComponent } from 'react';
import AdminLayout from '../layouts/admin-index';
import ActionsForm from '../components/ActionsForm';

const Index: FunctionComponent = () => {
  return (
    <AdminLayout>
      <h1 className="title">Registreer</h1>
      <ActionsForm />
    </AdminLayout>
  );
};

export default Index;
