import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Car, getCarList } from '../firebaseCars';
import { Action, actionsInit, printActions, registerActions } from '../firebaseRegistration';
import { useAuthState } from '../state/AuthContext';

const ActionsForm: FunctionComponent = () => {
  const { user } = useAuthState();
  const [carList, setCarList] = useState<Array<Car>>([]);
  const [selectedCar, setSelectedCar] = useState<Car | null>(null);
  const [distance, setDistance] = useState(0);
  const [distanceReady, setDistanceReady] = useState(false);
  const [actions, setActions] = useState<Action>(actionsInit);
  const [actionsReady, setActionsReady] = useState(false);
  const [actionsDate, setActionsDate] = useState<Date>(new Date());
  const [actionsDateReady, setActionsDateReady] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingHelp, setSavingHelp] = useState({ message: '', type: 'is-success' });

  console.log(user?.getIdToken());

  const deselectCar = () => {
    if (selectedCar) {
      setSelectedCar(null);
    }
  };

  const distanceNotReady = () => {
    if (distanceReady) {
      setDistanceReady(false);
    }
  };

  const actionsNotReady = () => {
    if (actionsReady) {
      setActionsReady(false);
    }
  };

  const actionsDateNotReady = () => {
    if (actionsDateReady) {
      setActionsDateReady(false);
    }
  };

  const updateDistance = (e: ChangeEvent) => {
    setDistance(Number((e.target as HTMLInputElement).value));
  };

  const clearForm = () => {
    setSelectedCar(null);
    setDistance(0);
    setDistanceReady(false);
    setActions(actionsInit);
    setActionsReady(false);
    setActionsDate(new Date());
    setActionsDateReady(true);
  };

  const completeRegistration = () => {
    if (user && selectedCar) {
      setSaving(true);
      registerActions(selectedCar, distance, actions, actionsDate, user)
        .then(() => {
          setSaving(false);
          setSavingHelp({ message: 'Je acties werden geregistreerd', type: 'is-success' });
          clearForm();
        })
        .catch(error => {
          console.error(error);
          setSaving(false);
          setSavingHelp({ message: 'Er liep iets mis probeer het opnieuw', type: 'is-danger' });
        });
    }
  };

  useEffect(() => {
    return getCarList(setCarList);
  }, []);

  return (
    <div className="registration-container">
      {savingHelp.message ? (
        <p className={classNames({ 'registration-container__help': true, [savingHelp.type]: true })}>
          {savingHelp.message}
        </p>
      ) : null}
      <div className="card car-selector">
        <div role="button" tabIndex={0} className="card-header" onClick={deselectCar} onKeyDown={deselectCar}>
          <p className="card-header-title car-selector__header">
            <span>Stap 1 - Selecteer voertuig:</span>
            {selectedCar ? (
              <span className="car-selector__item car-selector__item--small">{selectedCar.licencePlate}</span>
            ) : null}
          </p>
        </div>
        {selectedCar ? null : (
          <div className="card-content">
            <div className="content">
              <div className="car-selector__items-container">
                {carList.map(car => (
                  <div key={car.uid} className="car-selector__item-container">
                    <button
                      className="car-selector__item"
                      type="button"
                      onClick={() => {
                        setSelectedCar(car);
                        setSavingHelp({ message: '', type: 'is-success' });
                      }}
                    >
                      {car.licencePlate}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card distance-selector">
        <div role="button" tabIndex={0} className="card-header" onClick={distanceNotReady} onKeyDown={distanceNotReady}>
          <p className="card-header-title distance-selector__header">
            <span>Stap 2 - Geef kilometerstand in:</span>
            {distanceReady ? <span className="distance-selector__distance">{distance} km</span> : null}
          </p>
        </div>
        {distanceReady || !selectedCar ? null : (
          <div className="card-content">
            <div className="content">
              <div className="field has-addons distance-selector__distance-input">
                <div className="control is-expanded">
                  <input className="input" type="number" value={distance} onChange={updateDistance} />
                </div>
                <div className="control">
                  <span className="button">km</span>
                </div>
              </div>
              <div className="field distance-selector__save-btn">
                <div className="control">
                  <button
                    type="button"
                    className="button is-success is-fullwidth"
                    onClick={() => {
                      setDistanceReady(true);
                    }}
                  >
                    Sla op
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card action-selector">
        <div role="button" tabIndex={0} className="card-header" onClick={actionsNotReady} onKeyDown={actionsNotReady}>
          <p className="card-header-title action-selector__header">
            <span>Stap 3 - Welke acties heb je gedaan:</span>
            {actionsReady ? (
              <span className="action-selector__preview">
                {printActions(actions).map(actionText => (
                  <span key={actionText.replace(' ', '_')}>{actionText}, </span>
                ))}
              </span>
            ) : null}
          </p>
        </div>
        {!distanceReady || !selectedCar || actionsReady ? null : (
          <div className="card-content">
            <div className="content">
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <label htmlFor="clean-car-checkbox" className="label action-selector__input">
                        <input
                          id="clean-car-checkbox"
                          type="checkbox"
                          checked={actions.cleanCar}
                          onChange={e => {
                            setActions({ ...actions, cleanCar: e.target.checked });
                          }}
                        />
                        <span>Reinigen wagen</span>
                      </label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="clean-cargo-checkbox" className="label action-selector__input">
                        <input
                          id="clean-cargo-checkbox"
                          type="checkbox"
                          checked={actions.cleanCargoSpace}
                          onChange={e => {
                            setActions({ ...actions, cleanCargoSpace: e.target.checked });
                          }}
                        />
                        <span>Reinigen laadruimte</span>
                      </label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="check-oil-checkbox" className="label action-selector__input">
                        <input
                          id="check-oil-checkbox"
                          type="checkbox"
                          checked={actions.checkOil}
                          onChange={e => {
                            setActions({ ...actions, checkOil: e.target.checked });
                          }}
                        />
                        <span>Controle olie</span>
                      </label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="check-tires-checkbox" className="label action-selector__input">
                        <input
                          id="check-tires-checkbox"
                          type="checkbox"
                          checked={actions.checkTires}
                          onChange={e => {
                            setActions({ ...actions, checkTires: e.target.checked });
                          }}
                        />
                        <span>Controle banden</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field action-selector__save-btn">
                <div className="control">
                  <button
                    type="button"
                    className="button is-fullwidth is-success"
                    onClick={() => {
                      setActionsReady(true);
                    }}
                  >
                    Sla op
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card date-selector">
        <div
          role="button"
          tabIndex={0}
          className="card-header"
          onClick={actionsDateNotReady}
          onKeyDown={actionsDateNotReady}
        >
          <p className="card-header-title date-selector__header">
            <span>Stap 4 - Wanneer:</span>
            {actionsDateReady ? (
              <span className="date-selector__date">{actionsDate.toLocaleDateString('nl-BE')}, </span>
            ) : null}
          </p>
        </div>
        {!distanceReady || !selectedCar || !actionsReady || actionsDateReady ? null : (
          <div className="card-content">
            <div className="content">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="date"
                    value={actionsDate.toISOString().slice(0, 10)}
                    onChange={e => setActionsDate(new Date(e.target.value))}
                  />
                </div>
              </div>
              <div className="field date-selector__save-btn">
                <div className="control">
                  <button
                    type="button"
                    className="button is-fullwidth is-success"
                    onClick={() => {
                      setActionsDateReady(true);
                    }}
                  >
                    Sla op
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="field">
        <div className="control">
          <button
            disabled={!(selectedCar && distanceReady && actionsReady && actionsDateReady && user)}
            className={classNames({ 'button is-fullwidth is-success is-medium': true, 'is-loading': saving })}
            type="button"
            onClick={completeRegistration}
          >
            Klaar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionsForm;
